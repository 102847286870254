<template>
    <div class="l-inline l-center-v l-spread l-padded-x navigation-bar">
        <div v-if="!isLogoHidden" class="button-placeholder" />

        <IconButton v-else @click="$emit('navigateback')">
            <ArrowLeftIcon width="24" height="24" />
        </IconButton>

        <div>
            <transition name="fade" mode="out-in">
                <div
                    v-if="!isLogoHidden"
                    class="navigation-bar__logo"
                    :data-alt="title"
                >
                    <WhitelabledLogo height="28" />
                </div>

                <div v-else>{{ title }}</div>
            </transition>
        </div>

        <IconButton v-if="collapsed" @click="$emit('expand')">
            <ExpandMoreIcon width="24" height="24" />
        </IconButton>

        <IconButton v-else @click="$emit('collapse')">
            <ExpandLessIcon width="24" height="24" />
        </IconButton>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import ArrowLeftIcon from './icons/ArrowLeftIcon'
import ExpandLessIcon from './icons/ExpandLessIcon'
import ExpandMoreIcon from './icons/ExpandMoreIcon'
import IconButton from './IconButton'
import WhitelabledLogo from './WhitelabledLogo'

export default {
    name: 'NavigationBar',
    components: {
        ArrowLeftIcon,
        ExpandLessIcon,
        ExpandMoreIcon,
        IconButton,
        WhitelabledLogo,
    },
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState('tracker', ['assetTypes']),
        isLogoHidden() {
            switch (this.$route.name) {
                case 'globalAnimalActivityChart':
                case 'search':
                    return false
                default:
                    return true
            }
        },
        title() {
            if (this.$route.query.customNavigationTitle) {
                return this.$t(
                    `router.${this.$route.query.customNavigationTitle}`
                )
            }

            if (this.$route.name === 'allAssets') {
                return this.$t('allItems', { items: this.$t('shared.assets') })
            }

            if (this.$route.name === 'assetTypeAssets') {
                return this.$t('router.assetTypeAssets', {
                    assetType: this.assetTypes.find(
                        assetType =>
                            assetType.identifier ===
                            this.$route.params.assetType
                    )?.name,
                    items: this.$t('shared.assets'),
                })
            }

            if (this.$route.name === 'editAsset') {
                return this.$t('editItem', { item: this.$t('shared.asset') })
            }

            if (this.$route.name === 'locationAnimalActivityChart') {
                return this.$t('router.location')
            }

            if (this.$route.meta.isChart) {
                const { name } = this.$route.matched[
                    this.$route.matched.length - 2
                ]
                return this.$t(`router.${name}`)
            }

            return this.$t(`router.${this.$route.name}`)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "allItems": "All {items}",
        "editItem": "Edit {item}"
    },
    "de": {
        "allItems": "Alle {items}",
        "editItem": "{item} Bearbeiten"
    },
    "fr": {
        "allItems": "Tous {items}",
        "editItem": "{item} Editer"
    },
    "it": {
        "allItems": "Tutti {items}",
        "editItem": "Modificare {item}"
    }
}
</i18n>

<style lang="scss" scoped>
.navigation-bar {
    height: 64px;
    user-select: none;

    .button-placeholder {
        width: 34px;
    }

    &__logo {
        @include respond-to('for-mobile-down') {
            &:before {
                content: attr(data-alt);
            }
            img {
                display: none;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.15s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>

<style lang="scss">
.navigation-bar {
    .icon-button {
        margin: 0 5px;
    }
}
</style>
